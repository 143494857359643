/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap'); */

*{
    font-family: Arial, Helvetica, "Times New Roman", 'Manrope', sans-serif;
}

@font-face {
    font-family: 'Manrope' , sans-serif;
    src: url('fonts/Manrope-Bold.woff2') format('woff2'),
        url('fonts/Manrope-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope' , sans-serif;
    src: url('fonts/Manrope-ExtraBold.woff2') format('woff2'),
        url('fonts/Manrope-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope' , sans-serif;
    src: url('fonts/Manrope-SemiBold.woff2') format('woff2'),
        url('fonts/Manrope-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope' , sans-serif;
    src: url('fonts/Manrope-Light.woff2') format('woff2'),
        url('fonts/Manrope-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope' , sans-serif;
    src: url('fonts/Manrope-ExtraLight.woff2') format('woff2'),
        url('fonts/Manrope-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope' , sans-serif;
    src: url('fonts/Manrope-Medium.woff2') format('woff2'),
        url('fonts/Manrope-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope' , sans-serif;
    src: url('fonts/Manrope-Regular.woff2') format('woff2'),
        url('fonts/Manrope-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

