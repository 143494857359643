.brand-img {
    display: block;
    padding: 82px 0 0 0;

    @include max(767){ padding: 30px 0 0 0;}

    h2 {
        color: #000;
        font-family: 'Manrope' , sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2; max-width: 290px;
        @include max(991){max-width: 100%;}
        @include max(767){ font-size: 24px; font-weight: 600;}
    }

    img {
        width: 537.96px;
        //height: 440.16px;
        margin-top: -35px;
        @include max(991){display: none;}
    }

}

.brand-content {
    display: block;
    padding: 91px 0 0 0;
    @include max(991){padding: 50px 0 0 0;}
    @include max(767){ padding: 30px 0 0 0;}
    p {
        color: #FD0095;
        font-family: 'Manrope' , sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 116.1%;
        @include max(767){ font-size: 16px; line-height: 1.4;}
    }
    img{display: none;
        @include max(991){display: block; margin: 50px 0 0 0;}
    }
}