.dropdown {
    position: relative;
    display: none;

    button.dropbtn {
        background: transparent;
        border: navajowhite;
    }

    .dropdown-content {
        opacity: 0;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 150px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 12px 16px;
        z-index: 1;
        transition: opacity 0.3s ease, height 0.3s ease, color 0.5s ease;
        left: 50%;
        transform: translateX(-50%);
        

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                padding: 8px 0;

                button {
                    background-color: transparent;
                    border: none;
                    transition: all 0.5s;
                    text-align: left;

                    &:hover {
                        color: #FD0095;
                    }
                }
            }
        }
    }

    &:hover .dropdown-content {
        opacity: 1;
        height: auto;
    }
}
