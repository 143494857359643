section.my-header {
    position: absolute;
    width: 100vw;
    margin: auto;
    padding-top: 15px;

    .header-container {
        img {
            width: 138px;
            height: 32px;
        }

    }
}