.wrap404{text-align: center; padding: 160px 0;
    @include max(991){padding: 80px 0 50px 0;}
    h2{font-size: 50px;color: #23263A;font-weight: 900;padding: 0 0 20px 0;
        @include max(991){font-size: 40px;}
        @include max(767){margin: 0 0 10px 0;font-size: 30px;}
    }
    p{font-size: 20px;color:#222639;font-weight: normal;line-height: normal;padding: 0 0 5px 0; text-align:center; margin: 0px;
        @include max(991){font-size: 18px;}
        @include max(767){font-size: 16px;}
    }
    a{display: inline-block; margin: 20px 0 0 0;}
    // a{display: inline-block;color: #FE7F43;margin: 20px 0 0 0;
    //     &:hover{color: #000;}
    //     @include max(767){margin: 10px 0 0 0;font-size: 16px;}
    // }
}
