.privacyPolicyWrap{
    background-image: url("/images/privacy-policy.jpg");
    height: 600px;
    display: flex;
    align-items: center;

    h1{
        font-size: 54px;
        font-weight: 500;
        color: #fff;
    }
}