
.button-wrap{ display: flex;
    .btn{
        +.btn{margin-left: 20px;}
    }
}
.btn{background: #FD0095; padding: 8px 30px; font-size: 18px;  color: #FFF; font-weight: 700; text-decoration: none; display: inline-flex; align-items: center; gap: 10px; line-height: normal; border: 0px; cursor: pointer;}

.displayNoneTab{
    @include max(991){display: none !important;}
}

.formError{font-size: 14px; line-height: normal; margin: 10px 0 0 0; display: block; color: #ff0000;}


.question-outer-wrap{ padding: 70px 15px 40px 15px;


    ol{counter-reset: item; list-style-type: none;
        & > li{display: block;
            .question-wrap{
                pointer-events: none;
                opacity: .3;
            }
            &:before{content: counter(item);  counter-increment: item; position: absolute; width: 36px; height: 36px; border: 1px solid #FD0095; border-radius: 50%; font-size: 18px; font-weight: 400; line-height: 1; color: #FD0095; display: flex; align-items: center; justify-content: center; background: #fff;z-index: 2;}
            &.active{
                .question-wrap{
                    opacity: 1; pointer-events: all;
                }
            }
        }
    }

    @include max(767){padding: 40px 15px 40px 15px;}
    .question-wrap{ display: flex; flex-wrap: wrap; position: relative; padding: 0 0 90px 0;
        @include max(767){
            flex-direction: column;
        }
        &:before{position: absolute; left: 0px; content: ''; border-left: 1px solid #FD0095; height: 100%; left: 18px;  z-index: -1;
            @include max(767){display: none;}
        }
        .num-wrap{/*  width: 5%; position: relative;
            @include max(991){
                width: 7%;
            }
            @include max(767){width: auto; margin: 0 0 15px 0;}
            .num{width: 36px; height: 36px; border: 1px solid #FD0095; border-radius: 50%; font-size: 18px; font-weight: 400; line-height: 1; color: #FD0095; display: flex; align-items: center; justify-content: center; background: #fff;}
        */}
        .content-wrap{ width: 80%; padding: 0 50px 0px 5%;
            @include max(991){
                width: 100%; padding: 0 0px 0px 8%;
            }
            @include max(767){width: 100%; padding: 50px 0 0 0;}
            .head{font-size: 18px; color: #000; padding: 0 0 25px 0;
                @include max(767){font-size: 16px;}
            }
            .select-listing{
                ul{
                    li{list-style: none;
                        +li{padding-top: 15px;}
                        &:before{counter-increment: none; content: none;}
                        input{display: none;
                            &+label{ display: block; border: 1px solid #FD0095; font-size: 18px; color: #000; border-radius: 6px; text-decoration: none; padding: 10px 15px; font-weight: 400; cursor: pointer;
                                @include max(767){font-size: 16px;}
                            }
                            &:checked+label{font-weight: 600; color: #FD0095;}
                        }
                        &:hover{
                            label{color: #FD0095;}
                        }
                    }
                }
            }
        }
        .img-wrap{width: 20%;
            @include max(767){width: auto; display: flex; justify-content: center; margin: 30px 0 0 0;}
            img{display: block;}
        }
        .button-wrap{margin: 30px 0 0 5%; width: 100%;
            @include max(991){margin-left: 8%;}
            @include max(767){margin-left: 0px;}
        }
    }

    .done-click{width: 58px; height: 58px; border-radius: 50%; border: 1px solid #FD0095; display: flex; align-items: center; justify-content: center; background: #fff; margin: 0 0 0 -10px;
        @include max(767){ margin: 0px;}
    }

}

.result-wrap{display: flex; align-items: center; margin-top: -50px;
    @include max(991){ margin-top:0px;}
    @include max(767){margin: 30px 0 0 0; flex-direction: column;}
    .head{color: #000; font-size: 24px; font-weight: 700; padding: 0 0 30px 0;}
    .left-wrap{flex: 0 0 70%;
        @include max(991){
            flex: 0 0 80%;
        }
    }
    .right-wrap{
        @include max(991){
            flex: 0 0 20%;
        }
        @include max(767){
            margin: 30px 0 0 0;
        }
        img{display: block;}
    }
}

.tip{
    padding:15px 10px; background: #F3F3F3; display: flex; gap: 10px; align-items: flex-start; margin:30px 0 10px 5%; width: 71%;
    @include max(1199){width: 70%;}
    @include max(991){width: 100%; margin-left: 8%;}
    @include max(767){ margin-left: 0;}
    svg{flex: 0 0 25px;}
    p{font-size: 18px; font-weight: 500; color: #000;
        @include max(767){font-size: 16px;}
    }
}