.privacyPolicyContentWrap {

    .innerContentWrap {

        p {
            padding-top: 60px;
            margin: 0;

            a {
                color: #fd0095;
            }
        }
    }
}