@use "../mixins/mediaquery" as *;
@use "../mixins/flexbox" as *;
@use "../variables" as *;


.container{
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    @include max(1199){
        max-width: 100%;
    }
    @include min(1200){
        max-width: 1200px;
    }
}


.row{
    @include flexbox();
    @include flex-wrap(wrap);
    margin-left: -15px;
    margin-right: -15px;
    &.align-center{
        @include align-items(center);
    }
    &.justify-center{
        @include justify-content(center);
    }
    &.align-bottom{
        @include align-items(flex-end);
    }
    &.align-top{
        @include align-items(flex-start);
    }
    &.align-self-start{
        align-self: flex-start;
    }
}

[class^="col"]{
    @include flex(0,0,100%);
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}



.row{
    display: flex;
    justify-content: space-between;
    &.alignmiddle{
        align-items: center;
    }
}

// 100/(12/1)
.col1{
    @include min(992){
        @include flex(0,0,8.33333333333%);
        max-width: 8.33333333333%;
    }
}
// 100/(12/2)
.col2{
    @include min(992){
        @include flex(0,0,16.6666666667%);
        max-width: 16.6666666667%;
    }
}
// 100/(12/3)
.col3{
    @include min(992){
        @include flex(0,0,25%);
        max-width: 25%;
    }
}
.colmd3{
    @include min-max(768,991){
        @include flex(0,0,25%);
        max-width: 25%;
    }
}
// 100/(12/4)
.col4{
    @include min(992){
        @include flex(0,0,33.3333333333%);
        max-width: 33.3333333333%;
    }
}
//100/(12/5)
.col5{
    @include min(992){
        @include flex(0,0,41.6666666667%);
        max-width: 41.6666666667%;
    }
}
// 100/(12/6)
.col6{
    @include min(992){
        @include flex(0,0,50%);
        max-width: 50%;
    }
}
.col6sm{
    @include max(767){
        @include flex(0,0,50%);
        max-width: 50%;
    }
}

//100/(12/7)
.col7{
    @include min(992){
        @include flex(0,0,58.3333333333%);
        max-width: 58.3333333333%;
    }
}
// 100/(12/8)
.col8{
    @include min(992){
        @include flex(0,0,66.6666666667%);
        max-width: 66.6666666667%;
    }
}

// 100/(12/9)
.col9{
    @include min(992){
        @include flex(0,0,75%);
        max-width: 75%;
    }
}
// 100/(12/10)
.col10{
    @include min(992){
        @include flex(0,0,83.3333333333%);
        max-width: 83.3333333333%;
    }
}
// 100/(12/11)
.col11{
    @include min(992){
        @include flex(0,0,91.6666666666%);
        max-width: 91.6666666666%;
    }
}
.col12{
    @include min(992){
        @include flex(0,0,100%);
        max-width: 100%;
    }
}
