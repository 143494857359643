.assessment-content {

   

    p {
        color: #000;
        font-family: 'Manrope' , sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        
    }

    span {
        color: #000;
        font-family: 'Manrope' , sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        
    }

}

.assessment{padding: 80px 0 80px 0;
    @include max(767){padding: 50px 0;}
}

// .assessment-image {
//     display: block;
//     padding: 97px 0 0 0;
//     position: relative;

//     .score {

//         display: flex;

//         p {
//             color: #000;
//             font-family: 'Manrope' , sans-serif;
//             font-size: 18px;
//             font-style: normal;
//             font-weight: 400;
//             line-height: normal;
//             padding: 29px 0 0 15px;
//         }

//     }

//     .understand { padding: 20px 0;
//         position: relative;

//         img {
//             position: absolute;
//             right: 0;
//         }

//         p {
//             color: #000;
//             font-family: 'Manrope' , sans-serif;
//             font-size: 18px;
//             font-style: normal;
//             font-weight: 400;
//             line-height: normal;
//             position: absolute;
//             top: 7rem;
//             left: 16rem;
//         }
//     }

//     .improve {
//         display: flex;
//         position: absolute;
//         top: 25rem; 

//         p {
//             color: #000;
//             font-family: 'Manrope' , sans-serif;
//             font-size: 18px;
//             font-style: normal;
//             font-weight: 400;
//             line-height: normal;
//             padding: 58px 0 0 17px;
//         }
//     }

// }

.ass-right-wrap{padding: 0 0 0 29px;
    @include max(1199){padding: 0 0 0 20px;}
    @include max(991){padding: 50px 0 0 0; max-width: 600px;}
    p{color: #000; font-weight: 400; line-height: normal; font-size: 18px;}
    img{
        @include max(767){margin-bottom: 10px;}
    }
    .score-wrap{display: flex; align-items: flex-end;
        @include max(767){flex-direction: column; align-items: flex-start; margin: 0 0 30px 0;}
        p{margin-left: 15px; max-width: 235px;
            @include max(767){max-width: 100%; margin: 0px;}
        }
    }
    .understand-wrap{display: flex; align-items:flex-end; flex-direction: column; margin: 30px 0 -20px 0;
        @include max(767){align-items: flex-start;  margin: 0 0 30px 0;}
        p{@include max(767){max-width: 100%;}}
    }
    .improve{display: flex; align-items: flex-end; margin-top: 30px;
        @include max(767){flex-direction: column; align-items: flex-start;}
        p{max-width: 185px; margin: 0; padding: 30px 0 0 0;
            @include max(767){max-width: 100%; margin: 0px; padding: 0px;}
        }
    }
}