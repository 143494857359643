.data-and-analytics {
    padding: 100px 0 100px 0;

    @include max(991) {
        padding: 130px 0 0px 0;
    }

    @include max(767) {
        padding: 90px 0 0px 0;
    }
}



.data-content {
    display: block;
    padding: 0;

    h2 {
        color: #000;
        font-family: 'Manrope' , sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2;
        max-width: 490px;
        padding: 0 0 35px 0;


        @include max(991) {
            max-width: 100%;
        }

        @include max(767) {
            font-size: 28px;
        }

    }

    .data-image {
        display: flex;
        align-items: flex-start;

        @include max(767) {
            flex-direction: column;
            align-items: center;
        }

        img {
            max-width: 308px;
            margin: 150px 0 0 15px;

            @include max(1199) {
                max-width: 228px
            }

            @include max(991) {
                max-width: 308px;
            }

            @include max(767) {
                margin: 20px 0 0 0;
            }
        }

        .progress {
            flex: 0 0 330px;
            @include max(575) { flex:0 0 auto}


            .count {
                font-size: 5.5rem;
                font-weight: 700;
                letter-spacing: -4.7px;
                fill: #fd0095;
            }

            .progressVal {
                stroke-dashoffset: 1020;
                stroke-dasharray: 1020;
                transform-origin: center;
                transform: rotate(90deg);
            }
        }

    }

    .data-btn{
    
        .global-btn {
            padding: 10px 60px;
        }

    }


}

.data-para {
    background-color: #62165C;
    margin: 120px 0 0 0;
    padding: 50px;

    @include max(1199) {
        padding: 30px;
    }

    @include max(991) {
        margin: 60px 0 0 0;
    }

    h2 {
        color: #FFF;
        font-size: 2.4rem;
        font-weight: 500;

        margin-bottom: 30px;

        span {
            display: block;
            font-size: inherit;
            font-weight: 700;

        }
    }

    p {
        color: #FFF;
        font-family: 'Manrope' , sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;

        @include max(767) {
            font-size: 16px;
        }
    }



}