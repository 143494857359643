section.hero-section {
    display: block;
    // padding: 0 0 88px 0;
    @include max(991){ padding-bottom: 50px;}

    .hero-col {
      padding-bottom:90px;
      @include max(767){padding-bottom: 50px;}

        h1 {
            color: #FFF;
            font-family: 'Manrope' , sans-serif;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 125.1%;
            display: block;
            padding: 15px 0 0 0;
            @include max(767){font-size: 28px; padding: 20px 0 0 0;}
        }

        span {
            display: block;
            padding: 96px 0 0;
            color: #FFF;
            font-family: 'Manrope' , sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.44px;
            text-transform: uppercase;

            @include max(767){font-size: 14px; padding: 75px 0 0 0;}
        }

        p {
            color: #FFF;
            font-family: 'Manrope' , sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: block;
            padding: 25px 0 50px 0;
            @include max(767){font-size: 16px; padding: 20px 0 40px 0;}
        }

        strong {
            color: #FFF;
            font-family: 'Manrope' , sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: block;
            padding: 0 0 31px 0;
            @include max(767){font-size: 16px; padding: 0 0 20px 0;}
        }

    }
    .spline{

        img{max-width: 360px; margin: 0 auto; display: block;
            @include max(575){max-width: 100%;}
        }
    }
    canvas{ width: 100% !important; height: 100% !important;}
}
