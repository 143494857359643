.personalizedWrap {

    .personalized-image {
        text-align: center;
        svg{width: auto; height: auto; max-width: 350px;}
    }
    .pathstyle{fill:#f5ab16}
    .personalized-content {

        h2 {
            color: #000;
            font-family: "Manrope", sans-serif;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 116%;
            /* 41.76px */
        }

        
        .firstpara{
            padding-top: 60px;
        }
        
        p {
            color: #FD0095;
            font-family: "Manrope", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 116.1%;
            /* 27.864px */

        }

    }
}