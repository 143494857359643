.contact-form-wrap {
    // padding: 0 0 100px 0; -old
    // padding: 49px 0 100px 0; -old
    padding: 49px 0 0 0;

    @include max(991) {
        padding: 0px;
    }

    .contact-form {
        background: #F3F3F3;
        display: block;
        padding: 30px 30px 80px 30px;
        // margin: -150px 0 0 0;
        // max-width: 80%; -old

        @include max(991) {
            margin: 0px;
            max-width: 100%;
        }

        h2 {
            color: #FD0095;
            font-family: 'Manrope' , sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }


}


.contact-form-content {
    padding: 0 50px 0 0;
    position: relative;
    

    @include max(991) {
        padding: 50px 0 0 0;
    }

    h2 {
        color: #000;
        font-family: 'Manrope' , sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 25px;
    }

    p {
        color: #FD0095;
        font-family: 'Manrope' , sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        display: block;
        padding: 0 60px 0 0;

        @include max(1199) {
            padding: 0px;
        }

        strong {
            font-weight: 700;
        }

        &.lastp {
            padding: 0px;
            max-width: 250px;

            @include max(991) {
                max-width: 100%;
            }
        }
    }


    img {
        position: absolute;
        width: 240px;
        right: 0px;
        bottom: -200px;

        @include max(1199) {
            width: 170px;
            bottom: -140px;
        }

        @include max(991) {
            width: auto;
            bottom: 0px;
            margin: 50px 0 0 0;
            position: inherit;
        }
    }


}